import { useEffect, useState } from 'react';
import { getPermissions } from '../apis/users';
import { IRules } from '@common/interfaces/IAcl';
import { extractCountryFromAddress } from '@common/helpers/addresses';

export const usePermissions = () => {
  const [rules, setRules] = useState<IRules>(() => {
    const storedRole = localStorage.getItem('user_role');
    const storedCountry = extractCountryFromAddress();
    return {
      roles: storedRole ? JSON.parse(storedRole) : ['user'],
      userCountry: storedCountry,
    };
  });

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const storedPermissions = localStorage.getItem('user_role');
        if (!storedPermissions) {
          const response = await getPermissions();
          if (response.status === 200) {
            setRules({
              ...rules,
              roles: response.data.permissions,
            });
            localStorage.setItem('user_role', JSON.stringify(response.data.permissions));
          } else {
            setRules({
              ...rules,
              roles: ['user'],
            });
            localStorage.setItem('user_role', JSON.stringify(['user']));
          }
        }
      } catch (e) {
        setRules({
          ...rules,
          roles: ['user'],
        });
        localStorage.setItem('user_role', JSON.stringify(['user']));
      }
    };

    fetchPermissions();
  }, []);

  return { rules };
};
