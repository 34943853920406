import { PaletteMode } from '@mui/material';

export function Palette(mode: PaletteMode) {
  return {
    mode,
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#39C88A', // secondary
      light: mode === 'light' ? 'rgba(24, 52, 85, 0.05)' : 'rgba(255, 255, 255, 0.05)', // grey light
      dark: mode === 'light' ? 'rgba(24, 52, 85, 0.7)' : 'rgba(255, 255, 255, 0.7)', // black light
    },
    secondary: {
      main: mode === 'light' ? 'rgba(24,52,85,0.15)' : 'rgba(255,255,255,0.15)', // gray neutral
      light: mode === 'light' ? 'rgba(239,68,68,0.1)' : 'rgba(239,68,68,0.2)', // red light
    },
    greenColor: {
      darker: '#39C88A',
      light: '#58aa58',
      whiteGreen: '#d8e7d8',
    },
    blueColor: {
      darker: '#0C70BF',
      light: '#1B59F8CC',
      whiteBlue: '#F2F7FF',
    },
    greyColor: {
      light: '#697077',
      dark: '#21272A',
      border: '#DDE1E6',
    },
    state: {
      success: '#61C879',
      orange: '#E09F00',
      info: '#88C1F9',
      error: '#FF5E4F',
    },
    text: {
      primary: mode === 'light' ? '#183455' : '#ffffff', // black neutral / white
      redNeutral: '#EF4444', // red neutral
      greenNeutral: mode === 'light' ? 'rgba(30,70,32)' : 'rgba(100,200,100)', // green neutral
      orangeNeutral: mode === 'light' ? 'rgba(225,142,25)' : 'rgba(255,180,0)', // orange neutral
    },
    background: {
      default: mode === 'light' ? '#fff' : '#121212', // white / dark gray
      green: mode === 'light' ? 'rgba(237,247,237)' : 'rgba(30,70,32,0.2)',
      orange: mode === 'light' ? 'rgba(255,244,229)' : 'rgba(225,142,25,0.2)',
      dark: '#2c2c2c',
    },
    status: {
      danger: 'red',
    },
  };
}
