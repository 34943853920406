import React, { useMemo } from 'react';
import { CssBaseline, PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { Typography } from './typography';
import { Palette } from './palette';
import componentsOverride from './components';
import { selectTheme } from '@store/selectors';

// types.d.ts
declare module '@mui/material/styles' {
  interface TypeBackground {
    default: string;
    green: string;
    orange: string;
    dark: string;
  }
  interface TypeText {
    primary: string;
    redNeutral?: string;
    greenNeutral?: string;
    orangeNeutral?: string;
  }
  interface Palette {
    blueColor: {
      darker: string;
      light: string;
      whiteBlue: string;
    };
    greenColor: {
      darker: string;
      light: string;
      whiteGreen: string;
    };
    greyColor: {
      light: string;
      dark: string;
      border: string;
    };
    state: {
      success: string;
      orange: string;
      info: string;
      error: string;
    };
    status: {
      danger: string;
    };
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'text-lg': true;
    'text-md-b': true;
    'text-md': true;
    'text-sm-b': true;
    'text-sm': true;
    'text-xs-b': true;
    'text-xs': true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    borderless: true;
    'priority-urgent': true;
    'priority-important': true;
    'priority-moderate': true;
    'primary-filled': true;
    'priority-low': true;
  }
}

declare module '@mui/material/Avatar' {
  interface AvatarPropsVariantOverrides {
    'priority-urgent': true;
    'priority-important': true;
    'priority-moderate': true;
  }
}

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    'info-sbrain': true;
  }
}

export default function ThemeWrapper({ children }: { children: React.ReactNode }) {
  const { mode } = useSelector(selectTheme);

  const typography = Typography();
  const palette = Palette(mode as PaletteMode);

  const themeConfiguration = useMemo(() => {
    return {
      palette,
      typography,
    };
  }, [mode]);

  const theme = createTheme(themeConfiguration);
  // eslint-disable-next-line
  // @ts-ignore
  theme.components = componentsOverride(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
