import { AxiosResponse } from 'axios';
import { instance } from './baseApi';
import { config } from '../config/configurations';

export function me(): Promise<AxiosResponse> {
  return instance.get(`/${config.REACT_APP_API_VERSION}/me?expand=addresses`);
}

export function getPermissions(): Promise<AxiosResponse> {
  return instance.get(`/${config.REACT_APP_API_VERSION}/me/permissions`);
}

export function getUserDetails(userId: string, hcpId: string): Promise<AxiosResponse> {
  return instance.get(`/${config.REACT_APP_API_VERSION}/users/${userId}/hcps/${hcpId}`);
}

export function searchHcps(name: string, user_type = 'client'): Promise<AxiosResponse> {
  const url =
    user_type === 'client'
      ? `/${config.REACT_APP_API_VERSION}/me/hcps/search`
      : `/${config.REACT_APP_API_VERSION}/users/hcps/search`;

  const query = name
    ? name
        .split(' ')
        .filter((n) => n)
        .map((n) => `hcp_given_name~${n} OR hcp_family_name~${n}`)
        .join(' OR ')
    : '';

  return instance.get(url, {
    params: {
      name: query,
    },
  });
}

export function searchHcos(name: string, user_type = 'client'): Promise<AxiosResponse> {
  const url =
    user_type === 'client'
      ? `/${config.REACT_APP_API_VERSION}/me/hcos/search`
      : `/${config.REACT_APP_API_VERSION}/users/hcos/search`;
  return instance.get(url, {
    params: {
      name: name,
    },
  });
}

export function searchProducts(name: string, user_type = 'client'): Promise<AxiosResponse> {
  const url =
    user_type === 'client'
      ? `/${config.REACT_APP_API_VERSION}/me/products/search`
      : `/${config.REACT_APP_API_VERSION}/users/products/search`;
  return instance.get(url, {
    params: {
      name: name,
    },
  });
}

export function searchUsers(name: string, user_type = ''): Promise<AxiosResponse> {
  const url = `/${config.REACT_APP_API_VERSION}/users/search`;

  const name_query = name
    ? `given_name~${name} OR family_name~${name} OR display_name~${name}`
    : '';
  const user_query = user_type ? `user_type~${user_type}` : '';

  const filter_query = user_query
    ? name_query
      ? `${name_query} AND ${user_query}`
      : user_query
    : name_query;

  return instance.get(url, {
    params: {
      query: filter_query,
      page_size: 10,
    },
  });
}
