import React from 'react';
import { t } from 'i18next';
import { Box, Typography } from '@mui/material';
import AnimatedLogo from '@common/components/login/animated-logo';

export const LoginLoader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <AnimatedLogo />
        <Typography variant="text-lg">{t('LOADING_YOUR_INFORMATION')}</Typography>
      </Box>
    </Box>
  );
};
